import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';
import { LeadService } from '@services/lead.service';
import { GeraisService } from '@services/gerais.service';
import { SKU, DadosGerais, Escolaridade, Metodologia } from '@models/Gerais';
import CpfValidator from '@services/cpf.validator';
import EmailValidator from '@services/email.validator';
import { CampanhaService } from '@services/campanha.service';
import { Campanha, CampanhaPorId } from '@models/Campanha';
import { environment } from 'src/environments/environment';
import { SafeUrl } from '@angular/platform-browser';
import { Lead } from '../../models/Lead';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  //scroll no carrosel
  @ViewChild('boxCards') boxCardsRef!: ElementRef;

  appVersion = environment.version;
  sincronizadoOnlineSubscription: Subscription;
  isLoading = false;
  salvando = false;
  nomeUsuario: string;

  editarCampanha = false;
  registrarLead = false;
  currentUrl: string;

  contadores;

  formCampanha!: FormGroup;
  formLead!: FormGroup;

  exibeConfirmacao = false;
  mensagem = '';
  dialogoCallBack = () => { };

  // Campanha atual
  dadosCampanha: Campanha;
  dadosCampanhaTela: Campanha;
  dadosCampanhaPorId: CampanhaPorId;

  // Campanha
  cursos: SKU[];
  tiposCursos: string[] = [];
  campus: SKU[];
  turnos: SKU[];

  // Leads
  escolaridades: Escolaridade[];
  metodologias: Metodologia[];
  dadosGerais: DadosGerais;
  campanhas: any = [];
  municipios: string[];
  bairros: string[];
  locaisCaptura: string[];
  canal: string;
  idCampanha: number;
  loadingYduqs = true;
  indicadorEAD = false;
  dadosGeraisCarregado = false;
  cursoSelecionado: any = null;
  modalidadeSelecionada: any = null;
  turnoSelecionado: any = null;
  codCurso: any = null;
  codCampus: any = null;
  codCampusPai: any = null;
  codTurno: any = null;
  indModalidade: any = null;
  codigoInstituicaoInteresse: any = null;

  // qrCOde
  qrCode: string = null;
  isModalQrcode = false;
  public qrCodeDownloadLink: SafeUrl = '';
  selectedLocalCaptura: number = null;
  localCapturaQRCode: string = null;
  dadosSelected: any;

  // MultiSelect
  multiSelect: any;
  multiSelectSettings = {
    singleSelection: false,
    text: null,
    selectAllText: 'Selecionar todos',
    unSelectAllText: 'Desmarcar todos',
    enableSearchFilter: true,
    badgeShowLimit: 2,
    primaryKey: 'id',
    labelKey: 'descricao',
  };
  multiSelectList = [
    { id: 1, descricao: 'Valor 1' },
    { id: 2, descricao: 'Valor 2' },
    { id: 3, descricao: 'Valor 3' },
    { id: 4, descricao: 'Valor 4' },
    { id: 5, descricao: 'Valor 5' },
  ];

  // grid
  grid = 'horizontal';

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthService,
    private leadService: LeadService,
    private geraisService: GeraisService,
    private campanhaService: CampanhaService
  ) {
    this.nomeUsuario = this.authService.getNomeUsuario();
    this.currentUrl = window.location.href;
    console.log('Current URL:', this.currentUrl);
  }

  storageEventListener = (event: StorageEvent) => {     // Handle the storage event
    if (this.dadosGerais?.campanhas) {
      this.dadosGerais.campanhas.forEach(c => {
        if ("contadorAguardando" in localStorage) {

          let t = JSON.parse(localStorage.getItem("contadorAguardando")).find(l => { return l.idCampanha == c.idCampanha })
          c.leadsNaoEnviados = t ? t.quantidadeLead : 0;
        } else {
          c.leadsNaoEnviados = 0;
        }
      })
    }
  };

  ngOnInit(): void {
    // Verificar se é array ou não
    const dado = JSON.parse(localStorage.getItem("contadorAguardando"))
    this.sincronizadoOnlineSubscription = this.leadService.sincronizadoOnline.subscribe(() => {
      this.initContadores();
    })

    if (typeof dado === 'number') {
      console.log('tipoo', typeof dado);
      localStorage.removeItem('contadorAguardando');
      this.authService.logout();
      this.router.navigate(['login']);
      this.toastrService.success('Versão atualizada, faça o login novamente.');
    }

    window.addEventListener('storage', this.storageEventListener);
    // Inicializa formulários
    this.initFormCampanha();
    this.initFormLead();

    // Carrega Dados Gerais
    this.carregarDadosGerais();

    // Contadores
    this.initContadores();

    //QR Code
    // this.generateQRCode();
  }

  ngOnDestroy() {
    this.sincronizadoOnlineSubscription.unsubscribe();
    window.removeEventListener('storage', this.storageEventListener);
  }

  //Carregar Dados Gerais
  carregarDadosGerais() {
    this.geraisService.inicializar().then(
      () => {
        this.dadosGeraisCarregado = false;
        this.loadingYduqs = false;
        this.geraisService
          .getDadosGeraisOffline()
          .then(dadosGerais => {
            this.dadosGerais = dadosGerais;

            //this.dadosGerais.metodologias.sort((a, b) =>
            //  a.nomeMetodologia.localeCompare(b.nomeMetodologia)
            //); //ordenar por curso

            this.dadosGerais.campanhas.forEach(c => {
              if (!('contadorCadastroTotal' in localStorage)) {
                c.leadsRegistrados = 0;
              }
              if (!('contadorAguardando' in localStorage)) {
                c.leadsNaoEnviados = 0;
              }
              if (
                !('contadorCadastroTotal' in localStorage && 'contadorAguardando' in localStorage)
              ) {
                //do nothing
              } else {
                const total = JSON.parse(localStorage.getItem('contadorCadastroTotal'));
                const t = total.find(l => {
                  return l.idCampanha == c.idCampanha;
                });
                c.leadsRegistrados = t ? t.quantidadeLead : 0;
                if ('contadorAguardando' in localStorage) {
                  const t = JSON.parse(localStorage.getItem('contadorAguardando')).find(l => {
                    return l.idCampanha == c.idCampanha;
                  });
                  c.leadsNaoEnviados = t ? t.quantidadeLead : 0;
                } else {
                  c.leadsNaoEnviados = 0;
                }
              }
            });
            this.initContadores();
            this.selectAcao();
            console.log(dadosGerais);
          })
          .catch(error => {
            // Handle any errors that might occur during the execution
            console.error(error);
          });
      },
      err => this.toastrService.error(err)
    );

    // Dados da campanha para envio à api e preenchimento do formulário
    if ('funcao' in localStorage) {
      if (localStorage.getItem('funcao') === '4') {
        this.indicadorEAD = true;
      }
    }

    // Dados da campanha para envio à api e preenchimento do formulário
    if ('campanha' in localStorage) {
      this.campanhaService.getCampanha().subscribe(response => {
        this.dadosCampanha = response;
        this.formCampanha.setValue(this.dadosCampanha);
        console.log(this.dadosCampanha);
      });
    }

    // Dados da campanha para exibição na tela
    if ('campanhaTela' in localStorage) {
      this.campanhaService.getCampanhaTela().subscribe(response => {
        this.dadosCampanhaTela = response;
        console.log(this.dadosCampanhaTela);
      });
    }
  }

  // Contadores
  initContadores() {
    this.isLoading = true;
    this.leadService.atualizarAguardando().then(() => {
      this.leadService.getLeadsRegistrados().subscribe(
        response => {
          this.isLoading = false;
          if (response.sucesso) {
            localStorage.setItem('contadorCadastroTotal', JSON.stringify(response.resultado));
            if (this.dadosGerais) {
              this.dadosGerais.campanhas.forEach(c => {
                const t = response.resultado.find(l => {
                  return l.idCampanha == c.idCampanha;
                });
                c.leadsRegistrados = t ? t.quantidadeLead : 0;
                if ('contadorAguardando' in localStorage) {
                  const lead = JSON.parse(localStorage.getItem('contadorAguardando')).find(l => {
                    return l.idCampanha == c.idCampanha;
                  });
                  c.leadsNaoEnviados = lead?.quantidadeLead ? lead?.quantidadeLead : 0;
                } else {
                  c.leadsNaoEnviados = 0;
                }
              });
              if (!('contadorAguardando' in localStorage)) {
                const obj = [];
                this.dadosGerais.campanhas.forEach(c => {
                  obj.push({
                    idCampanha: c.idCampanha,
                    quantidadeLead: 0,
                  });
                });
                localStorage.setItem('contadorAguardando', JSON.stringify(obj));
              }
            }
          } else {
            this.toastrService.error(response.mensagem);
          }
        },
        error => {
          this.isLoading = false;
          this.atualizarAguardando();
        }
      );
    });
  }

  // Formulário Campanha
  initFormCampanha() {
    this.formCampanha = new FormGroup({
      acao: new FormControl('', [Validators.required]),
      estado: new FormControl('', [Validators.required]),
      marca: new FormControl('', [Validators.required]),
      regional: new FormControl('', [Validators.required]),
      municipio: new FormControl('', [Validators.required]),
      bairro: new FormControl(''),
      local: new FormControl('', [Validators.required]),
    });

    if (this.dadosCampanha) {
      this.formCampanha.reset(this.dadosCampanha);
    }
  }

  // Formulário Lead
  initFormLead() {
    this.formLead = new FormGroup({
      localCaptura: new FormControl(''),
      nomeLead: new FormControl('', [Validators.required]),
      //regional: new FormControl('', [Validators.required]),
      //nucleoHUB: new FormControl('', [Validators.required]),
      //UF: new FormControl('', [Validators.required]),
      //Cidade: new FormControl('', [Validators.required]),
      //Bairro: new FormControl('', [Validators.required]),
      emailLead: new FormControl('', [Validators.required, EmailValidator]),
      telefoneLead: new FormControl('', [Validators.required]),
      cpfLead: new FormControl('', [CpfValidator]),
      empresa: new FormControl(''),
      escolaridade: new FormControl(''),
      tipoCurso: new FormControl('GRADUAÇÃO'),
      curso: new FormControl('', [Validators.required]),
      unidadeInteresse: new FormControl(''),
      metodologia: new FormControl(''),
      turno: new FormControl(''),
      campus: new FormControl(''),
      anoConclusaoEnsinoMedio: new FormControl(0, [Validators.required]),
    });
  }

  dadosIniciais(tipo) {
    if (tipo !== 'campanha') {
      // Para uso no formulário de Leads
      this.cursos = this.dadosGerais.skus;

      // Ordenar por nome do curso
      this.cursos.sort((a, b) => a.nomeCurso.localeCompare(b.nomeCurso));

      // Remover duplicatas usando filter e Set
      const nomesUnicos = new Set();
      this.cursos = this.cursos.filter(course => {
        if (!nomesUnicos.has(course.nomeCurso)) {
          nomesUnicos.add(course.nomeCurso);
          return true;
        }
        return false;
      });
    }
  }

  // ---------------------------------
  selectAcao() {
    console.log(this.formCampanha.controls['acao'].value);
    const acao = this.dadosGerais.campanhas.filter(x => {
      return x.descricaoCampanha == this.formCampanha.controls['acao'].value;
    })[0];
    if (acao) {
      console.log('acao', acao);
      this.canal = acao.canal;
      this.idCampanha = acao.idCampanha;
      this.formCampanha.controls['estado'].setValue(acao.ufCampanhaUsuario);
      this.formCampanha.controls['marca'].setValue(acao.marcaCampanhaUsuario);
      this.formCampanha.controls['regional'].setValue(acao.regionalCampanhaUsuario);
      this.formCampanha.controls['nucleo'].setValue(acao.nucleoHUbCampanhaUsuario);
      if (acao.cidade) {
        this.municipios = [];
        this.municipios.push(acao.cidade);
        this.formCampanha.controls['municipio'].setValue(acao.cidade);
      } else {
        //Recupera uma lista de municipios dos dados gerais, sem repetição.
        const municipiosUnicos = new Set<string>(); //Esse set é uma lista que não aceita elementos repetidos
        this.dadosGerais.locaisCampanha.forEach(obj => {
          municipiosUnicos.add(obj.cidade);
        });
        this.municipios = Array.from(municipiosUnicos);
        if (this.municipios.length == 1) {
          this.formCampanha.controls['municipio'].setValue(this.municipios[0]);
        }
      }
      //Organiza os bairros sem repetição
      if (this.canal != 'H') {
        const bairrosUnicos = new Set<string>(); //Esse set é uma lista que não aceita elementos repetidos
        this.dadosGerais.locaisCampanha.forEach(obj => {
          bairrosUnicos.add(obj.bairro);
        });
        this.bairros = Array.from(bairrosUnicos);
        if (this.bairros.length == 1) {
          this.formCampanha.controls['bairro'].setValue(this.bairros[0]);
        }
        //Organiza os locais de captura sem repetição
        const locaisCapturaUnicos = new Set<string>(); //Esse set é uma lista que não aceita elementos repetidos

        this.dadosGerais.locaisCampanha.forEach(obj => {
          locaisCapturaUnicos.add(obj.localCaptura);
        });
        this.locaisCaptura = Array.from(locaisCapturaUnicos);
        if (this.locaisCaptura.length == 1) {
          this.formCampanha.controls['local'].setValue(this.locaisCaptura[0]);
        }
      } else {
        this.locaisCaptura = [];
        this.locaisCaptura.push(acao.escola);
        this.formCampanha.controls['local'].setValue(acao.escola);
      }
    }
  }

  selectEstado(apagarProximos?: boolean) {
    if (apagarProximos) {
      this.dadosCampanha = this.formCampanha.value;
      this.dadosCampanha = new Campanha(
        this.dadosCampanha.acao,
        this.dadosCampanha.estado,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      this.formCampanha.reset(this.dadosCampanha);
    }

    //this.geraisService.getMarcas().subscribe(response => {
    //  this.marcas = this.dadosCampanha.marca && this.marcas ? this.marcas : response;
    //});
  }

  selectMarca(e, apagarProximos?: boolean) {
    if (apagarProximos) {
      this.dadosCampanha = this.formCampanha.value;
      this.dadosCampanha = new Campanha(
        this.dadosCampanha.acao,
        this.dadosCampanha.estado,
        this.dadosCampanha.marca,
        null,
        null,
        null,
        null,
        null,
        null
      );
      this.formCampanha.reset(this.dadosCampanha);
    }

    const idMarca = Number(e.value ? e.value : e);

    //this.geraisService
    //  .getRegionais(idMarca, this.indicadorEAD, this.dadosCampanha.estado)
    //  .subscribe(response => {
    //    this.regionais = this.dadosCampanha.regional && this.regionais ? this.regionais : response;
    //  });
  }

  selectRegional(e, apagarProximos?: boolean) {
    if (apagarProximos) {
      this.dadosCampanha = this.formCampanha.value;
      this.dadosCampanha = new Campanha(
        this.dadosCampanha.acao,
        this.dadosCampanha.estado,
        this.dadosCampanha.marca,
        this.dadosCampanha.regional,
        null,
        null,
        null,
        null,
        null
      );
    }

    // precisa atualizar para recuperar o estado
    this.formCampanha.reset(this.dadosCampanha);

    //const idRegional = Number(e.value ? e.value : e),
    //  siglaEstado = this.formCampanha.controls['estado'].value;

    //this.geraisService
    //  .getNucleosHubs(idRegional, this.indicadorEAD, siglaEstado)
    //  .subscribe(response => {
    //    this.nucleos = this.dadosCampanha.nucleo && this.nucleos ? this.nucleos : response;
    //  });
  }

  selectNucleo(apagarProximos?: boolean) {
    if (apagarProximos) {
      this.dadosCampanha = this.formCampanha.value;
      this.dadosCampanha = new Campanha(
        this.dadosCampanha.acao,
        this.dadosCampanha.estado,
        this.dadosCampanha.marca,
        this.dadosCampanha.regional,
        this.dadosCampanha.nucleo,
        null,
        null,
        null,
        null
      );
    }

    // precisa atualizar para recuperar o estado
    this.formCampanha.reset(this.dadosCampanha);

    //const siglaEstado = this.formCampanha.controls['estado'].value;

    //this.geraisService.getMunicipios(siglaEstado).subscribe(response => {
    //  this.municipios =
    //    this.dadosCampanha.municipio && this.municipios ? this.municipios : response;
    //});
  }

  selectMunicipio(e, apagarProximos?: boolean) {
    if (apagarProximos) {
      this.dadosCampanha = this.formCampanha.value;
      this.dadosCampanha = new Campanha(
        this.dadosCampanha.acao,
        this.dadosCampanha.estado,
        this.dadosCampanha.marca,
        this.dadosCampanha.regional,
        this.dadosCampanha.nucleo,
        this.dadosCampanha.municipio,
        null,
        null,
        null
      );
      this.formCampanha.reset(this.dadosCampanha);
    }

    //const idMunicipio = Number(e.value ? e.value : e);

    //this.geraisService.getBairros(idMunicipio).subscribe(response => {
    //  this.bairros = this.dadosCampanha.bairro && this.bairros ? this.bairros : response;
    //});
  }

  setBairro() {
    this.dadosCampanhaPorId.bairro = this.getLocaisByCampanhaId(this.dadosCampanhaPorId.idCampanha).filter(x => {
      return x.chave == this.formLead.controls["localCaptura"].value;
    })[0].bairro;
  }

  selectBairro() {
    const locaisCapturaUnicos = new Set<string>(); //Esse set é uma lista que não aceita elementos repetidos
    const locais = this.dadosGerais.locaisCampanha.filter(x => {
      return x.bairro == this.formCampanha.controls['bairro'].value;
    });
    locais.forEach(obj => {
      locaisCapturaUnicos.add(obj.localCaptura);
    });
    this.locaisCaptura = Array.from(locaisCapturaUnicos);
    if (this.locaisCaptura.length == 1) {
      this.formCampanha.controls['local'].setValue(this.locaisCaptura[0]);
    }
  }

  selectCurso() {

    // Limpar o campo turno, campus e metodologia quando o curso é alterado
    this.formLead.controls['metodologia'].setValue(null);
    this.formLead.controls['turno'].setValue(null);
    this.formLead.controls['campus'].setValue(null);

    // atualiza o curso
    this.cursoSelecionado = this.formLead.controls['curso'].value;

    // Atualizar codCurso
    const curso = this.dadosGerais.skus.find(x => x.nomeCurso === this.cursoSelecionado);
    this.codCurso = curso ? curso.codCurso : null;

    this.getMetodologiasPorCurso(this.cursoSelecionado);
  }

  getMetodologiasPorCurso(cursoSelecionado) {
    // Filtrar skus com base no curso selecionado
    const skusFiltrados = this.dadosGerais.skus.filter(item => item.nomeCurso === cursoSelecionado);

    // Remover duplicados baseados na modalidade
    const modalidadesUnicas = new Set();
    const skusUnicos = skusFiltrados.filter(item => {
      const modalidadeTrimmed = item.modalidade.trim().toLowerCase(); // Normalizar para comparação
      if (!modalidadesUnicas.has(modalidadeTrimmed)) {
        modalidadesUnicas.add(modalidadeTrimmed);
        return true;
      }
      return false;
    });

    // Ordenar por modalidade
    skusUnicos.sort((a, b) => a.modalidade.localeCompare(b.modalidade));

    return skusUnicos; // Retornar a lista de skus únicos e ordenados
  }

  selectMetodologia() {

    // Limpar o campo turno e campus quando a metodologia é alterada
    this.formLead.controls['turno'].setValue(null);
    this.formLead.controls['campus'].setValue(null);

    // Obter o valor da metodologia selecionada
    this.cursoSelecionado = this.formLead.controls['curso'].value;
    this.modalidadeSelecionada = this.formLead.controls['metodologia'].value;

    // Atualizar indModalidade
    const sku = this.dadosGerais.skus.find(x =>
      x.nomeCurso === this.cursoSelecionado &&
      x.modalidade === this.modalidadeSelecionada
    );
    this.indModalidade = sku ? sku.indModalidade : null;

    // Atualizar a lista de turnos com base no curso e metodologia selecionada
    this.turnos = this.getTurnosPorCurso(this.cursoSelecionado, this.modalidadeSelecionada);
  }

  getTurnosPorCurso(cursoSelecionado: string, metodologiaSelecionada: string) {

    // Filtrar skus com base no curso selecionado e na modalidade
    const skusFiltrados = this.dadosGerais.skus.filter(item =>
      item.nomeCurso === cursoSelecionado &&
      item.modalidade === metodologiaSelecionada &&
      item.idCampanha === this.idCampanha
    );

    // Remover duplicatas baseadas no nome do turno
    const turnosUnicos = new Set();
    const turnosFiltrados = skusFiltrados.filter(item => {
      const nomeTurnoTrimmed = item.nomeTurno.trim().toLowerCase(); // Normalizar para comparação
      if (!turnosUnicos.has(nomeTurnoTrimmed)) {
        turnosUnicos.add(nomeTurnoTrimmed);
        return true;
      }
      return false;
    });

    // Ordenar por turno
    turnosFiltrados.sort((a, b) => a.modalidade.localeCompare(b.modalidade));

    // Retornar a lista de turnos únicos
    return turnosFiltrados;

  }

  selectTurno() {

    // Limpar o campo turno quando o turno é alterado
    this.formLead.controls['campus'].setValue(null);

    // Obter o valor da metodologia selecionada
    this.cursoSelecionado = this.formLead.controls['curso'].value;
    this.modalidadeSelecionada = this.formLead.controls['metodologia'].value;
    this.turnoSelecionado = this.formLead.controls['turno'].value;

    // Atualizar codTurno
    const turno = this.dadosGerais.skus.find(x =>
      x.nomeCurso === this.cursoSelecionado &&
      x.modalidade === this.modalidadeSelecionada &&
      x.nomeTurno === this.turnoSelecionado
    );
    this.codTurno = turno ? turno.codTurno : null;

    // Atualizar a lista de campus com base no curso, metodologia e turno selecionados
    this.campus = this.getCampusPorCurso(this.cursoSelecionado, this.modalidadeSelecionada, this.turnoSelecionado);
  }

  getCampusPorCurso(cursoSelecionado: string, metodologiaSelecionada: string, turnoSelecionado: string) {

    // Filtrar skus com base no curso selecionado, metodologia, turno e idCampanha
    const skusFiltrados = this.dadosGerais.skus.filter(item =>
      item.nomeCurso === cursoSelecionado &&
      item.modalidade === metodologiaSelecionada &&
      item.nomeTurno === turnoSelecionado &&
      item.idCampanha === this.idCampanha
    );

    // Remover duplicatas baseadas no nome do campus
    const campusUnicos = new Set();
    const campusFiltrados = skusFiltrados.filter(item => {
      const nomeCampusTrimmed = item.nomeCampus.trim().toLowerCase(); // Normalizar para comparação
      if (!campusUnicos.has(nomeCampusTrimmed)) {
        campusUnicos.add(nomeCampusTrimmed);
        return true;
      }
      return false;
    });

    // Ordenar a lista de campus por nome
    campusFiltrados.sort((a, b) => a.nomeCampus.localeCompare(b.nomeCampus));

    // Atualizar codCampus
    const campusSelecionado = campusFiltrados.find(item => item.nomeCampus === this.formLead.controls['campus'].value);
    this.codCampus = campusSelecionado ? campusSelecionado.codCampus : null;
    this.codCampusPai = campusSelecionado ? campusSelecionado.codCampusPai : null;

    // Retornar a lista de campus únicos e ordenados
    return campusFiltrados;
  }

  selectCampus() {

    // Atualizar codCampus
    const campus = this.dadosGerais.skus.find(x =>
      x.nomeCurso === this.cursoSelecionado &&
      x.modalidade === this.modalidadeSelecionada &&
      x.nomeTurno === this.turnoSelecionado &&
      x.nomeCampus === this.formLead.controls['campus'].value
    );
    this.codCampus = campus ? campus.codCampus : null;
    this.codCampusPai = campus ? campus.codCampusPai : null;
    this.codigoInstituicaoInteresse = campus ? campus.codIes : null;

  }

  // ---------------------------------

  // Etapas
  etapa(etapa: string) {
    if (etapa === 'editarCampanha') {
      this.editarCampanha = true;
      this.registrarLead = false;

      this.dadosIniciais('campanha');
      this.formCampanha.reset(this.dadosCampanha);
    } else if (etapa === 'registrarLead') {
      this.editarCampanha = false;
      this.registrarLead = true;
      // Dados gerais - Lead
      this.dadosIniciais('lead');

      this.formLead.reset();
      this.formLead.controls['escolaridade'].setValue(null);
      this.formLead.controls['metodologia'].setValue(null);
      setTimeout(() => {
        document
          .getElementById('Formulario')
          .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 250);
    } else {
      this.editarCampanha = false;
      this.registrarLead = false;
      this.exibeConfirmacao = false;
      this.isLoading = false;
      this.initContadores();
      this.initFormLead();
    }
  }

  // Registrar lead
  cadastrarLead(item: any) {
    this.editarCampanha = false;
    this.canal = item.canal;
    this.idCampanha = item.idCampanha;

    this.initFormLead();
    if (this.canal != 'H') {
      this.formLead.controls['localCaptura'].setValidators([Validators.required]);
      this.formLead.controls['anoConclusaoEnsinoMedio'].clearValidators();
    } else {
      this.formLead.controls['anoConclusaoEnsinoMedio'].setValidators([Validators.required]);
      this.formLead.controls['localCaptura'].clearValidators();
      //this.formLead.controls['cpfLead'].clearValidators();
      //this.formLead.controls['cpfLead'].setValidators([CpfValidator]);
      //this.formLead.controls['metodologia'].clearValidators();
    }
    this.registrarLead = true;
    console.log(this.dadosCampanha);
    //atribuir valores da campanha selecionada
    this.dadosCampanhaPorId = new CampanhaPorId(item);

    //// Verificar se já existe o curso, caso contrário adicionar o novo.
    //this.dadosGerais.skus.forEach(x => {
    //  if (!this.tiposCursos.includes(x.tipoCurso)) {
    //    this.tiposCursos.push(x.tipoCurso);
    //    console.log(!this.tiposCursos.includes(x.tipoCurso));
    //  }
    //});

    // Dados gerais - Lead
    this.dadosIniciais('lead');

    this.formLead.reset();
    this.formLead.controls['escolaridade'].setValue(null);
    this.formLead.controls['metodologia'].setValue(null);
  }

  // pegar o localCampanha de acordo com o idCampanha - inserir o get dentro do ngfor no select de local de captura
  getLocaisByCampanhaId(idCampanha: number) {
    return this.dadosGerais.locaisCampanha.filter(item => item.idCampanha === idCampanha);
  }

  // Modal de confirmação
  exibirConfirmacao(tipo) {
    this.exibeConfirmacao = true;

    if (tipo === 'editarCampanha') {
      this.isLoading = true;

      this.mensagem = 'Confirma a alteração da campanha?';

      this.dialogoCallBack = () => {
        this.salvarCampanha(this.formCampanha.value);
      };
    } else if (tipo === 'registrarLead') {
      this.isLoading = true;

      this.mensagem = 'Confirma a inclusão do lead?';

      this.dialogoCallBack = () => {
        this.salvarLead();
      };
    } else if (tipo === 'atualizarDados') {
      this.mensagem = 'Confirma a atualização da base de dados? O formulário abaixo será limpo!';

      this.dialogoCallBack = () => {
        this.sincronizarDadosGerais();
      };
    } else if (tipo === 'logOut') {
      this.mensagem =
        'Atenção, ao se desconectar, você perderá a possibilidade de criação de Leads caso esteja sem conexão com a internet!';

      this.dialogoCallBack = () => {
        this.authService.logout();
        this.router.navigate(['login']);
      };
    } else {
      // outra confirmação
    }
  }

  salvarCampanha(campanha) {
    this.salvando = true;
    if (this.canal == 'H') {
      campanha.bairro = '';
    }
    // Armazena as informações para envio à api
    this.campanhaService.postCampanha(campanha);

    // Recupera os novos dados de campanha
    this.dadosCampanha = this.campanhaService.dadosCampanha;

    // Dados à serem salvos
    //const dados = {
    //  evento: this.eventos.filter(i => i.idEvento === parseInt(campanha.evento))[0],
    //  estado: this.estados.filter(i => i.siglaEstado === campanha.estado)[0],
    //  marca: this.marcas.filter(i => i.idMarca === parseInt(campanha.marca))[0],
    //  regional: this.regionais.filter(i => i.idRegional === parseInt(campanha.regional))[0],
    //  nucleo: this.nucleos.filter(i => i.idNucleoHub === parseInt(campanha.nucleo))[0],
    //  municipio: this.municipios.filter(i => i.idMunicipio === parseInt(campanha.municipio))[0],
    //  bairro: this.bairros.filter(i => i.idBairro === parseInt(campanha.bairro))[0],
    //  local: this.locaisCaptura.filter(i => i.idLocalCaptura === parseInt(campanha.local))[0],
    //};

    // Armazena as informações para apresentação em tela
    /*this.campanhaService.postCampanhaTela(dados);*/

    // Recupera os novos dados de campanha
    this.dadosCampanhaTela = this.campanhaService.dadosCampanhaTela;

    // Retorna à tela principal
    this.salvando = false;
    this.etapa('reset');
  }

  atualizarAguardando() {
    this.leadService.atualizarAguardando().then(() => {
      if (this.dadosGerais?.campanhas) {
        this.dadosGerais.campanhas.forEach(c => {
          if ('contadorAguardando' in localStorage) {
            const t = JSON.parse(localStorage.getItem('contadorAguardando')).find(l => {
              return l.idCampanha == c.idCampanha;
            });
            c.leadsNaoEnviados = t ? t.quantidadeLead : 0;
          } else {
            c.leadsNaoEnviados = 0;
          }
        });
      }
    });
    //console.log(this.dadosGerais.campanhas)
  }

  alertar() {
    console.log(this.dadosGerais.campanhas);
  }

  salvarLead() {
    this.salvando = true;

    // salva o local
    const localSalvo = this.formLead.controls['localCaptura'].value;

    // Recupera os novos dados de campanha
    this.dadosCampanhaPorId;
    const body = new Lead(this.dadosCampanhaPorId);
    const localFilter = this.getLocaisByCampanhaId(body.idCampanha).filter((local) => local.chave === localSalvo);
    body.canal = this.dadosCampanhaPorId.canal;
    body.nomeCampanha = this.dadosCampanhaPorId.descricaoCampanha;
    body.marca = this.dadosCampanhaPorId.marcaCampanhaUsuario;
    body.localCapturaEscola = this.formLead.controls['localCaptura'].value ? localFilter[0].localCaptura : this.dadosCampanhaPorId.escola;
    body.nomeLead = this.formLead.controls['nomeLead'].value;
    body.emailLead = this.formLead.controls['emailLead'].value;
    body.telefoneLead = this.formLead.controls['telefoneLead'].value;
    body.cpfLead = this.formLead.controls['cpfLead'].value;
    body.empresa = this.formLead.controls['empresa'].value;
    body.escolaridade = this.formLead.controls['escolaridade'].value;
    body.tipoCurso = this.formLead.controls['tipoCurso'].value
      ? this.formLead.controls['tipoCurso'].value
      : 'GRADUAÇÃO';
    body.curso = this.formLead.controls['curso'].value;
    body.marca = this.dadosCampanhaPorId.marcaCampanhaUsuario;
    //campos novos
    body.codCurso = this.codCurso;
    body.codCampus = this.codCampus;
    body.codCampusPai = this.codCampusPai;
    body.codTurno = this.codTurno;
    body.turno = this.formLead.controls['turno'].value;
    body.indModalidade = this.indModalidade;
    body.codigoInstituicaoInteresse = this.codigoInstituicaoInteresse;
    if (body.canal == 'H') {
      body.campus = this.dadosCampanhaPorId.campus;
      body.cidade = this.dadosCampanhaPorId.cidadeCampanhaUsuario;
    } else {
      body.cidade = localFilter[0].cidade;
      body.uf = localFilter[0].uf;
      body.campus = this.formLead.controls['campus'].value;
    }
    body.metodologia = this.formLead.controls['metodologia'].value;
    body.anoConclusaoEnsinoMedio = this.formLead.controls['anoConclusaoEnsinoMedio'].value;
    body.idMetodologia = this.dadosGerais.metodologias.find(x => {
      return x.nomeMetodologia == body.metodologia;
    })?.chave;
    body.idEscolaridade = this.dadosGerais.escolaridades.find(x => {
      return x.nomeEscolaridade == body.escolaridade;
    })?.chave;

    //Gravação do lead
    this.leadService
      .postLead(body)
      .then(
        response => {
          // Verifica a condição para definir a mensagem
          const mensagem = this.formLead.valid &&
            this.formLead.get('cpfLead').valid &&
            this.formLead.get('cpfLead').value &&
            this.formLead.get('curso').value &&
            this.formLead.get('metodologia').value &&
            this.formLead.get('turno').value &&
            this.formLead.get('campus').value
            ? 'Oportunidade salva com sucesso!'
            : 'Lead salvo com sucesso!';

          this.toastrService.success(mensagem);
          this.salvando = false;

          //this.etapa('reset');
          this.formLead.reset();
          this.formLead.controls['escolaridade'].setValue(null);
          this.formLead.controls['metodologia'].setValue(null);
          console.log(response);

          // deixa o local de captura antigo salvo
          this.formLead.controls['localCaptura'].setValue(localSalvo);
        },
        error => {
          this.salvando = false;

          this.toastrService.warning('Não foi possível salvar o lead, salvando offline');

          console.log(error);
        }
      )
      .finally(() => {
        this.atualizarAguardando();
        //this.etapa('reset');
      });
  }

  sincronizarLeads() {
    this.isLoading = true;
    this.leadService.sincronizar().then(() => {
      this.initContadores();
      this.atualizarAguardando();
    });
    this.initContadores();
  }

  // Faz a atualização dos Dados Gerais manualmente
  sincronizarDadosGerais() {
    this.exibeConfirmacao = false;

    this.geraisService.inicializar(true).then(
      res => {
        if (res) {
          this.dadosGeraisCarregado = false;
          this.campanhaService.limparCampanha(); // Limpar a campanha gravado no local storage
          this.dadosCampanha = null;
          this.dadosCampanhaTela = null;
          this.formCampanha.reset();
          this.dadosIniciais('campanha');
          this.toastrService.success('Atualização concluída!');
          this.carregarDadosGerais();
          this.initContadores();
          // window.location.reload();
        }
      },
      err => {
        this.toastrService.error(err);
      }
    );
  }

  // Controler

  onGrid(action: string) {
    if (action === 'horizontal') {
      this.grid = 'horizontal';
    } else {
      this.grid = 'vertical';
    }
  }

  generateQRCode(item: any) {
    // https://cordobo.com/2444-angular4-qrcode-generator-component/
    //generateQRCode é acessado duais vezes, primeiro para mandar os dados dos items e depois no select do localcaptura

    setTimeout(() => {
      if (this.selectedLocalCaptura === null) {
        this.dadosSelected = [
          {
            idCampanha: item?.idCampanha,
            canal: item?.canal,
            marca: item?.marcaCampanhaUsuario,
            urlRedirectQRCode: item?.urlRedirectQRCode,
            idCampanhaQRCode: item?.idCampanhaQRCode
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
          },
        ];
      }
      if (item?.canal !== 'H' && this.selectedLocalCaptura) {
        this.qrCode = `${this.currentUrl
          }qr-captacao?chaveLocal=${encodeURIComponent(this.selectedLocalCaptura)
          }&idUsuario=${this.authService.getIdusuario()
          }&idCampanhaQRCode=${this.dadosSelected[0].idCampanhaQRCode
          }&urlRedirectQRCode=${encodeURIComponent(this.dadosSelected[0].urlRedirectQRCode)
          }&marca=${encodeURIComponent(this.dadosSelected[0].marca)
          }&canal=${encodeURIComponent(this.dadosSelected[0].canal)
          }
        `;
      } else if (item?.canal == 'H') {
        // High School
        this.qrCode = `${this.currentUrl
          }qr-captacao?idUsuario=${this.authService.getIdusuario()
          }&idCampanhaQRCode=${this.dadosSelected[0].idCampanhaQRCode
          }&urlRedirectQRCode=${encodeURIComponent(this.dadosSelected[0].urlRedirectQRCode)
          }&marca=${encodeURIComponent(this.dadosSelected[0].marca)
          }&canal=${encodeURIComponent(this.dadosSelected[0].canal)
          }`;
      }
      console.log(this.qrCode);
    }, 300);
  }

  modalQrcode(action: string, item: any) {

    // abrir modal ou fechar
    if (action === 'open') {
      this.isModalQrcode = true;
    } else if (action === 'exit') {
      this.isModalQrcode = false;

      // zerar qrCode
      this.selectedLocalCaptura = null;
      this.qrCode = null;
      this.dadosSelected = null;
    }
    //atribuir valores da campanha selecionada;
    this.dadosCampanhaPorId = item;

    // Quando for Hig School ja inicia direto
    this.generateQRCode(item);
    /* if (item?.canal == 'H') {
      this.generateQRCode(item)
    } */
  }

  // donwload QRCode
  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  shareImage() {
    if (navigator.share) {
      const urlToShare = this.qrCode;
      navigator
        .share({
          title: 'QR Code Image',
          url: urlToShare,
        })
        .then(() => {
          console.log('Image shared successfully.');
        })
        .catch(error => {
          console.error('Error sharing image:', error);
        });
    } else {
      console.log('Sharing not supported in this browser.');
      // Handle sharing fallback for browsers that don't support navigator.share
    }
  }
  logRequiredFields() {
    //Essa funcao de diz qual campo do formulario não está valido
    event.preventDefault();
    Object.keys(this.formLead.controls).forEach(fieldName => {
      const control = this.formLead.controls[fieldName];
      if (control.status != 'VALID') {
        console.log(fieldName);
      }
    });
  }

  //scroll inteligente - Escolher qual vai usar

  // ao clicar no card o scroll avança até ele
  //scrollToCard(index: number) {
  //  if (this.boxCardsRef && this.boxCardsRef.nativeElement) {
  //    const cardWidth = this.boxCardsRef.nativeElement.querySelector('.card').clientWidth;
  //    this.boxCardsRef.nativeElement.scrollLeft = index * cardWidth;
  //  }
  // html > <div class="box-cards" *ngIf="dadosGerais" #boxCards>
  //}
  //
  //// Ao clicar no card ele reordena.
  //  reorderItems() {
  //  const lastItem = this.dadosGerais.campanhas.pop();
  //  this.dadosGerais.campanhas.unshift(lastItem);
  //}

}
